.textaria {
  font: inherit;
  color: currentColor;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  letter-spacing: inherit;
  animation-duration: 10ms;
  font-size: 18px;
}
h2 small {
  font-size: 0.5em;
  display: inline-block;
  padding-left: 0.7em;
}
.font-sm {
  font-size: 0.85rem !important;
}
.action-area {
  margin-top: 1rem;
}

.action-area button {
  margin-right: 1rem;
}

.required-input {
  color: #ff0000;
  border: 1px #ff0000;
  font-size: 1.5rem;
}
.input-label {
  font-size: 1rem;
  margin: 5px 15px 5px 0px;
  font-weight: 500;
}
.cards {
  padding: 15px;
  height: 100%;
}
.input-select {
  margin-top: 16px;
  margin-bottom: 8px;
}
.PhoneInputInput {
  border-radius: 5px;
  border-color: #dcdcdc3d;
  /* margin-top: 10px; */
  padding: 15px;
}
.scroll-to-top button {
  position: fixed;
  bottom: 22px;
  border-radius: 40%;
  background: #117aa7;
  color: #fff;
  z-index: 998;
  font-size: 30px;
  border: none;
  float: right;
  margin-left: auto;
  right: 0;
  margin-right: 10px;
}
.scroll-to-top .icon {
  animation: scrollTop 0.3s alternate ease infinite;
}

@keyframes scrollTop {
  from {
    transform: translateY(3px);
  }
  to {
    transform: translateY(-2px);
  }
}
.specification-label {
  font-weight: 500;
  font-size: smaller;
}
